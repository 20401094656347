import Dom = require("Everlaw/Dom");
import Project = require("Everlaw/Project");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Server = require("Everlaw/Server");
import User = require("Everlaw/User");

/**
 * Pop up a confirmation if the user has an elevated role and is not on the project. A user who is
 * not on the project but does not have an elevated role (e.g., an org admin?) will NOT see the
 * confirmation dialog.
 *
 * Use as a method decorator. Note that this decorator only works for methods so it can't be used
 * for standalone functions, lambdas, or functions stored to a variable. If that becomes
 * prohibitive, we might also add a function wrapping option here.
 *
 * Note that this changes the return value of the function from X to Promise<X>.
 */
export = function ElevatedRoleConfirm(action = "making changes") {
    return function (target: any, name: string, descriptor: PropertyDescriptor) {
        const method = descriptor.value; // references the method being decorated
        descriptor.value = function (...args) {
            // ...args are the arguments that are being passed to the original method
            return new Promise<any>((resolve, reject) => {
                if (User.me.hasElevatedRole() && !User.me.isActiveUser() && Server.isLive()) {
                    QueryDialog.create({
                        title: "Elevated role confirmation",
                        submitText: "Proceed",
                        prompt: Dom.div([
                            Dom.p([
                                "You are ",
                                Dom.b(action),
                                " on project ",
                                Dom.b(Project.CURRENT.display()),
                                " as a user not on the project, using your elevated role.",
                            ]),
                            Dom.p("Please confirm that this action is authorized and correct."),
                        ]),
                        onCancel: () => {
                            reject();
                            return true;
                        },
                        onSubmit: () => {
                            // use .apply and this to give the method proper context.
                            resolve(method.apply(this, args));
                            return true;
                        },
                    });
                } else {
                    resolve(method.apply(this, args));
                }
            });
        };
    };
};
