import Base = require("Everlaw/Base");
import Category = require("Everlaw/Category");
import CodeAsPrevious = require("Everlaw/CodeAsPrevious");
import CodeOrder = require("Everlaw/CodeOrder");
import Arr = require("Everlaw/Core/Arr");
import Is = require("Everlaw/Core/Is");
import DepoFullScreenConstants = require("Everlaw/Depo/DepoFullScreenConstants");
import Freeform = require("Everlaw/Freeform");
import SpecialFolders = require("Everlaw/Homepage/SpecialFolders");
import LocalStorage = require("Everlaw/LocalStorage");
import type * as Metadata from "Everlaw/Metadata";
import { Canonical } from "Everlaw/Canonical";
import Obj = require("Everlaw/Core/Obj");
import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");
import ScrollViewer = require("Everlaw/Review/ScrollViewer");
import User = require("Everlaw/User");
import dojo_cookie = require("dojo/cookie");
import DocumentGroupFilter = require("Everlaw/DocumentGroupFilter");
import DocumentGroupType = require("Everlaw/DocumentGroupType");
import { WritingAssistantTemplateId } from "Everlaw/Argument/View/WritingAssistantDialog/util/WritingAssistantTemplate";
import View from "Everlaw/Review/View";
import { PanelLayout } from "Everlaw/Review/FullScreenTypes";
import type { HitHighlightType } from "Everlaw/Review/HitQuery";
import * as moment from "moment-timezone";
import { SortType, TextVariantSort } from "Everlaw/Review/DifferenceViewer/DiffViewerSidePanelSort";

// The level that the preference is on. See the compare method of Preference.
enum PrefLevel {
    NO_PREF_DEFAULT = "Global Default", // When there is no preference in the chain
    GLOBAL_DEFAULT = "Everlaw Default",
    PROJECT_DEFAULT = "Project Default",
    USER_DEFAULT = "User Default",
    USER_VALUE = "User Value",
}

/**
 * Represents preferences for users and projects.
 * A preference can target a specific user and project, a user for all their projects, a project for
 * all its users, or all users on all projects. Those that are more specific are higher priority.
 * We prefer to use preferences that are higher priority. However, a preference can be locked, in
 * which case it will override preferences that are higher priority than it. See Preference.Chain.
 */
class Preference<V> {
    // Having a null user indicates a preference that applies to all users.
    user: User | null = null;
    // Having a null project indicates a preference that applies to all projects.
    project: number | null = null;
    set: string;
    name: string;
    value: V;
    locked: boolean;
    lastModified = 0;
    constructor(params: any) {
        Object.assign(this, params);
        if (this.user) {
            this.user = Base.get(User, params.user);
        }
    }
    getValue(): V {
        return this.value;
    }
    setValue(value: V, locked?: boolean, time?: number): void {
        this.value = structuredClone(value);
        if (Is.defined(locked)) {
            this.locked = locked;
        }
        this.setLastModified(time);
    }
    setLocked(locked: boolean, time?: number): void {
        this.locked = locked;
        this.setLastModified(time);
    }
    setLastModified(time?: number): void {
        this.lastModified = Is.defined(time) ? time : moment.now();
    }
    commit(onPageUnload: boolean): void {
        var content = {
            set: this.set,
            name: this.name,
            value: JSON.stringify(this.value),
            locked: this.locked,
            lastModified: this.lastModified,
            projectDefault: !this.isUserSpecific(),
        };
        const url = this.project
            ? `/${this.project}/preferences/setPref.rest`
            : "/users/setPref.rest";
        if (onPageUnload) {
            Rest.postOnPageUnload(url, content);
        } else {
            Rest.post(url, content);
        }
    }
    /**
     * Sort from lowest priority to highest priority:
     *
     * All Users, All Projects (global defaults)
     * All Users, One Project  (project defaults)
     * One User, All Projects  (user defaults)
     * One User, One Project   (specific settings)
     */
    compare(other: Preference<V>): number {
        if (!!this.user !== !!other.user) {
            return this.user ? 1 : -1;
        } else if (!!this.project !== !!other.project) {
            return this.project ? 1 : -1;
        }
        return 0;
    }
    isUserSpecific(): boolean {
        return this.user !== null;
    }
    isProjectSpecific(): boolean {
        return this.project !== null;
    }
    isGlobalDefault(): boolean {
        return !this.isUserSpecific() && !this.isProjectSpecific();
    }
    isUserValue(): boolean {
        return this.isUserSpecific() && this.isProjectSpecific();
    }
    getLevel(): PrefLevel {
        if (this.isUserValue()) {
            return PrefLevel.USER_VALUE;
        } else if (this.isUserSpecific()) {
            return PrefLevel.USER_DEFAULT;
        } else if (this.isProjectSpecific()) {
            return PrefLevel.PROJECT_DEFAULT;
        } else {
            return PrefLevel.GLOBAL_DEFAULT;
        }
    }
}

module Preference {
    export const Level = PrefLevel;

    export class Chain<V> {
        set: string;
        name: string;
        prefs: Preference<V>[];
        defaultVal: V;
        // isVisible currently unused
        isVisible: boolean;
        isValid(val: any) {
            return true;
        }
        constructor(params: {
            defaultVal: V;
            isVisible?: boolean;
            isValid?(val: any): boolean;
            _display?(val: V): string;
        }) {
            // mixin the set of preference defaults/functions
            Object.assign(this, params);
        }
        // prefList should be ordered by priority (see Preference.compare)
        load(set: string, name: string, prefList: Preference<V>[]): void {
            this.set = set;
            this.name = name;
            this.prefs = Arr.sort(prefList.filter((pref) => this.isValid(pref.value)));
        }
        /**
         * Get the highest priority value that can be used. See getPrioritizedPreference.
         */
        get(): V {
            const pref = this.getPrioritizedPreference();
            // return a clone so that we can compare updated values with the stored values!
            return !pref ? structuredClone(this.defaultVal) : structuredClone(pref.getValue());
        }
        /**
         * Get the level of the highest priority preference. See getPrioritizedPreference.
         */
        getPriorityLevel(): PrefLevel {
            const pref = this.getPrioritizedPreference();
            return !pref ? PrefLevel.NO_PREF_DEFAULT : pref.getLevel();
        }
        /**
         * Get the highest priority preference that can be used. We walk up the list of preferences from low
         * priority to high priority, stopping if we encounter a locked preference.
         */
        protected getPrioritizedPreference(): Preference<V> {
            let priorityPref = null;
            for (const pref of this.prefs) {
                priorityPref = pref;
                // Stop as soon as you hit the first
                if (pref.locked) {
                    break;
                }
            }
            // returns the highest priority preference. A null means using the default value.
            return priorityPref;
        }
        private getValue(prefLevel: PrefLevel): {
            val: V;
            isLocked: boolean;
            lastModified: number;
        } {
            for (const pref of this.prefs) {
                if (prefLevel === pref.getLevel()) {
                    return {
                        val: structuredClone(pref.value),
                        isLocked: pref.locked,
                        lastModified: pref.lastModified,
                    };
                }
            }
            return { val: structuredClone(this.defaultVal), isLocked: true, lastModified: -1 };
        }
        /**
         * @returns
         *      val: The value of the User's Preference.
         *          defaultVal if the user doesn't have a Preference.
         *      isLocked: If the User's Preference is locked.
         *          true if this user doesn't have a Preference.
         */
        getUserValue(): { val: V; isLocked: boolean; lastModified: number } {
            return this.getValue(PrefLevel.USER_VALUE);
        }
        /**
         * @returns
         *      val: The value of the User's Default Preference.
         *          defaultVal if the user doesn't have a Preference.
         *      isLocked: If the User's Default Preference is locked.
         *          true if this user doesn't have a Preference.
         */
        getUserDefault(): { val: V; isLocked: boolean; lastModified: number } {
            return this.getValue(PrefLevel.USER_DEFAULT);
        }
        /**
         * @returns
         *      val: The value of the current Project's Preference.
         *          defaultval if the Project doesn't have a Preference.
         *      isLocked: If the current Project's Preference is locked.
         *          true if this current Project doesn't have a Preference.
         */
        getProjectDefault(): { val: V; isLocked: boolean; lastModified: number } {
            return this.getValue(PrefLevel.PROJECT_DEFAULT);
        }
        /**
         * @returns
         *      val: The value of the Global Preference.
         *          defaultval if there is no Global Preference.
         *      isLocked: If the current Global Preference is locked.
         *          true if there is no Global Preference.
         */
        getGlobalDefault(): { val: V; isLocked: boolean; lastModified: number } {
            return this.getValue(PrefLevel.GLOBAL_DEFAULT);
        }
        /**
         * If there is a locked Preference in this Chain.  Returns true if there isn't a Preference
         * defined in this chain.
         */
        isLocked() {
            return this.prefs.length === 0 || this.prefs.some((p) => p.locked);
        }
        isLoaded() {
            return !!this.prefs;
        }
        matchUserValueToPriority(onPageUnload?: boolean) {
            return this.setUserValue(this.get(), onPageUnload);
        }
        matchUserDefaultToPriority(locked?: boolean, onPageUnload?: boolean) {
            return this.setUserDefault(this.get(), locked, onPageUnload);
        }
        matchProjectDefaultToPriority(locked?: boolean) {
            return this.setProjectDefault(this.get(), locked);
        }
        matchGlobalDefaultToPriority(locked?: boolean) {
            return this.setGlobalDefault(this.get(), locked);
        }
        setUserValue(value: V, onPageUnload?: boolean) {
            return this._set(value, true, true, false, onPageUnload);
        }
        setUserDefault(value: V, locked?: boolean, onPageUnload = false) {
            return this._set(value, false, true, locked, onPageUnload);
        }
        setProjectDefault(value: V, locked?: boolean) {
            return this._set(value, true, false, locked, false);
        }
        setGlobalDefault(value: V, locked?: boolean) {
            return this._set(value, false, false, locked, false);
        }
        // The update methods work like the set methods but do NOT commit to the backend.
        // This is mainly for when a browser is subscribed to preferences to update concurrently; there's
        // no reason to (and may even cause errors) to commit to the backend if the source was the backend itself.
        updateUserValue(value: V, onPageUnload?: boolean) {
            return this._set(value, true, true, false, onPageUnload, true);
        }
        updateUserDefault(value: V, locked?: boolean, onPageUnload = false) {
            return this._set(value, false, true, locked, onPageUnload, true);
        }
        updateProjectDefault(value: V, locked?: boolean) {
            return this._set(value, true, false, locked, false, true);
        }
        updateGlobalDefault(value: V, locked?: boolean) {
            return this._set(value, false, false, locked, false, true);
        }
        private _set(
            value: V,
            isProjectSpecific: boolean,
            isUserSpecific: boolean,
            locked?: boolean,
            onPageUnload?: boolean,
            doNotCommit?: boolean,
        ) {
            // Don't set invalid values or any preference involving a project if we don't have one set.
            if (!this.isValid(value) || (isProjectSpecific && !Project.CURRENT)) {
                return false;
            }
            let pref: Preference<V> | null = null;
            for (const tmp of this.prefs) {
                if (
                    tmp.isUserSpecific() === isUserSpecific
                    && tmp.isProjectSpecific() === isProjectSpecific
                ) {
                    pref = tmp;
                    break;
                }
            }
            if (!pref) {
                // If it's a project default, it applies to all users.
                const userId = isUserSpecific ? User.me.id : null;
                // If it's a user default, it applies to all projects.
                const projId = isProjectSpecific ? Project.CURRENT.id : null;
                pref = new Preference({
                    user: userId,
                    project: projId,
                    set: this.set,
                    name: this.name,
                    locked: locked,
                });
                Arr.insertSorted(this.prefs, pref);
            } else if (
                pref.value === value
                || JSON.stringify(value) === JSON.stringify(pref.value)
            ) {
                if (!Is.defined(locked) || locked === pref.locked) {
                    // Either the values themselves are equal, or their json representations are - we don't
                    // need to save the same value! (Note that we give out a copy of the internal value, so
                    // devs shouldn't be able to update the value in place if it is an object)
                    return;
                }
                // We're just updating the locked field of an existing preference.
                pref.setLocked(locked);
            }
            pref.setValue(value);
            if (!doNotCommit) {
                pref.commit(onPageUnload);
            }
            return true;
        }
        display() {
            return this._display(this.get());
        }
        private _priorityDescription(pref: Preference<unknown>) {
            if (pref.isGlobalDefault()) {
                return "Everlaw Default";
            } else if (!pref.isUserSpecific()) {
                return "Project Default";
            } else if (!pref.isProjectSpecific()) {
                return (
                    (pref.user.equals(User.me) ? "Your" : pref.user.display() + "'s") + " Default"
                );
            } else {
                return (
                    (pref.user.equals(User.me) ? "Your" : pref.user.display() + "'s") + " Setting"
                );
            }
        }
        displayChain() {
            var values: string[] = [];
            var hasDefault = false;
            for (var pref of this.prefs) {
                if (pref.isGlobalDefault()) {
                    hasDefault = true;
                }
                values.push(
                    this._priorityDescription(pref)
                        + ": "
                        + this._display(pref.value)
                        + (pref.locked ? " (locked)" : ""),
                );
                if (pref.locked) {
                    break;
                }
            }
            if (!hasDefault) {
                values.unshift("Everlaw Default: " + this._display(this.defaultVal));
            }
            return values;
        }
        private _display(val: V) {
            // TODO: Does this val need to have special HTML characters escaped?
            return JSON.stringify(val);
        }
    }
    /**
     * The TimeChain class overrides the default priority of preferences in favor of prioritizing by last modified.
     * This is currently only used for Code as Previous.
     *
     * EP-2323 Note: Be careful using this class if you also intend to use the preference in the backend.
     * Currently, the backend only gets preferences by the default priority of preferences (by user/project levels).
     * Please see PreferenceService.java at PreferenceService#get for more info.
     * If this class ends up only being useful for code as previous preferences, we may choose to rewrite the logic
     * for EP-2323 so that it fits better using the old preference priority (wiping/matching user preferences).
     */
    export class TimeChain<V> extends Chain<V> {
        constructor(params: {
            defaultVal: V;
            isVisible?: boolean;
            isValid?(val: unknown): boolean;
            _display?(val: V): string;
        }) {
            super(params);
        }

        // Rather than return by priority order, we instead search for the preference that has the latest last
        // modified timestamp and return that.
        // This priority ignores the locked variable of preferences.
        protected override getPrioritizedPreference(): Preference<V> {
            let priorityPref = null;
            let lastModified = -1;
            this.prefs.forEach((pref) => {
                // We use >= instead of > so that on ties, it defaults back to the standard priority.
                if (pref.lastModified >= lastModified) {
                    priorityPref = pref;
                    lastModified = pref.lastModified;
                }
            });
            return priorityPref;
        }
    }

    export interface PDFOptions {
        singlePDF?: boolean;
        batesPage?: boolean;
        batesStamp?: boolean;
        onlyEverlawStamps?: boolean;
        exportVideoClips?: boolean;
        includeCaptions?: boolean;
        metadata?: Metadata.FieldId[];
        freeformCodes?: Freeform.CodeId[];
        highlight?: boolean;
        copies?: number;
        preserveOrder?: boolean;
        inline?: boolean;
        testimonyLineNumbers?: boolean;
        exportGroupsAsSinglePDFs?: boolean;
        tableOfContents?: boolean;
        storyDetails?: boolean;
        imagesToLetter?: boolean;
    }

    export interface SortSelections {
        assignments: number;
        searches: number;
        documentSets: number;
        storybuilder: number;
        batchesExports: number;
        binders: number;
    }

    export var GENERAL = {
        emailOnMessage: new Chain<boolean>({
            isVisible: true,
            defaultVal: true,
            isValid: function (v) {
                return !!v === v;
            },
        }),
        uploadEmails: new Chain<boolean>({
            defaultVal: true,
            isValid: function (v) {
                return !!v === v;
            },
        }),
        productionEmails: new Chain<boolean>({
            defaultVal: true,
            isValid: function (v) {
                return !!v === v;
            },
        }),
        singlePDFOptions: new Chain<PDFOptions>({
            defaultVal: {},
        }),
        batchPDFOptions: new Chain<PDFOptions>({
            defaultVal: {
                batesStamp: true,
                onlyEverlawStamps: true,
            },
        }),
        depoDraftPDFOptions: new Chain<PDFOptions>({
            defaultVal: {},
        }),
        npsOptions: new Chain<{ lastCompleted: number; count: number }>({
            defaultVal: {
                lastCompleted: 0,
                count: 0,
            },
            isVisible: false,
            isValid: function (v) {
                return Is.object(v) && Is.number(v.lastCompleted) && Is.number(v.count);
            },
        }),
        walkMeShown: new Chain<boolean>({
            defaultVal: true,
        }),
        dismissedAnnouncements: new Chain<number[]>({
            defaultVal: [],
        }),
        showCompatibleShortcuts: new Chain<boolean>({
            defaultVal: false,
        }),
        dismissedAdminSuspensionAnnouncement: new Chain<number>({
            defaultVal: 0,
        }),
        autoConfirmBatchCode: new Chain<boolean>({
            defaultVal: false,
        }),
        autoConfirmCancel: new Chain<boolean>({
            defaultVal: false,
        }),
    };

    export var HOME = {
        whatsNewViewed: new Chain<number>({
            defaultVal: 0,
        }),
        whatsNewCollapsed: new Chain<number>({
            defaultVal: 0,
        }),
        currentFolder: new Chain<number | string>({
            defaultVal: SpecialFolders.ALL,
        }),
        folderOrder: new Chain<string[]>({
            defaultVal: [],
        }),
        // 0 maps to HomeTable.SortType.TIMESTAMP;
        // 1 maps to HomeTable.SortType.ALPHABET;
        sortSelections: new Chain<SortSelections>({
            defaultVal: {
                assignments: 0,
                searches: 0,
                documentSets: 0,
                storybuilder: 0,
                batchesExports: 0,
                binders: 0,
            },
        }),
    };

    export type DashboardSize = "small" | "big";

    export interface SearchGroupOptions {
        useDefault: boolean;
        defaultGroup: string;
        defaultFilter: string;
    }

    export var SEARCH = {
        docWidgets: new Chain<string[]>({
            defaultVal: ["contents", "bates", "processed"],
        }),
        ecaDocWidgets: new Chain<string[]>({
            defaultVal: ["project", "bates", "contents"],
        }),
        reviewWidgets: new Chain<string[]>({
            defaultVal: ["rated", "coded", "tagged"],
        }),
        ecaReviewWidgets: new Chain<string[]>({
            defaultVal: ["promoted", "tagged", "priorSearch"],
        }),
        metadataWidgets: new Chain<string[]>({
            defaultVal: [
                Canonical.FROM,
                Canonical.CUSTODIAN,
                Canonical.TO,
                Canonical.DATE,
                Canonical.FILENAME,
            ],
        }),
        // Stores either a SavedResultsTableView id if the user was last on a saved view, otherwise
        // the raw json representing the columns in the last used view and their widths.
        // Maintain the invariant that when a user is on the search page,
        // Preference.SEARCH.visibleColumns should be set to some viewId number if and only if
        // the search being viewed has that same viewId.
        visibleColumns: new Chain<number | [string, string, number][]>({
            defaultVal: [],
        }),
        hiddenResultsTableViews: new Chain<number[]>({
            defaultVal: [],
            isValid: Is.array,
        }),
        newKeyboardShortcutsViewed: new Chain<boolean>({
            defaultVal: false,
        }),
        // Stores an object so we can add additional per-property configuration in the future.
        dashboard: new Chain<{ category: string }[]>({
            isVisible: true,
            defaultVal: [{ category: "File_Path" }, { category: "Metadata_Date" }],
            isValid: Is.array,
        }),
        dashboardSize: new Chain<DashboardSize>({
            defaultVal: "small",
        }),
        dashboardShowPopular: new Chain<boolean>({
            defaultVal: true,
        }),
        dashboardDndInstructionsViewed: new Chain<boolean>({
            defaultVal: false,
        }),
        datavisShowNoValue: new Chain<{ [key: string]: boolean }>({
            defaultVal: {},
        }),
        quickReview: new Chain<boolean>({
            defaultVal: false,
        }),
        defaultGrouping: new Chain<SearchGroupOptions>({
            defaultVal: {
                useDefault: true,
                defaultGroup: DocumentGroupType.Attachments.display(),
                defaultFilter: DocumentGroupFilter.SearchHits.displayRemoved(),
            },
        }),
        recentSearchTerms: new Chain<string[]>({
            defaultVal: [],
            isValid: Is.array,
        }),
    };

    const getDefaultRecommendationPagePreference: () => Chain<boolean> = () => {
        return new Chain<boolean>({
            defaultVal: true,
        });
    };

    export const RECPAGES = {
        AllPages: getDefaultRecommendationPagePreference(),
        Analytics: getDefaultRecommendationPagePreference(),
        Homepage: getDefaultRecommendationPagePreference(),
        Uploads: getDefaultRecommendationPagePreference(),
        Productions: getDefaultRecommendationPagePreference(),
        Search: getDefaultRecommendationPagePreference(),
        SearchTermReports: getDefaultRecommendationPagePreference(),
        DataVisualizer: getDefaultRecommendationPagePreference(),
        PredictiveCoding: getDefaultRecommendationPagePreference(),
        DocumentClustering: getDefaultRecommendationPagePreference(),
        Storybuilder: getDefaultRecommendationPagePreference(),
        ReviewWindow: getDefaultRecommendationPagePreference(),
        MessageCenter: getDefaultRecommendationPagePreference(),
        ProjectSettings: getDefaultRecommendationPagePreference(),
        DatabaseSettings: getDefaultRecommendationPagePreference(),
        AssignmentGroups: getDefaultRecommendationPagePreference(),
        /**
         * This is never set by the user and should only be used for test
         * {@link Recommendation recommendations}.
         */
        Test: getDefaultRecommendationPagePreference(),
    };

    export interface SystemCodes {
        order: {
            [catId: string]: {
                sequence: string[];
                alphabetical: boolean;
            };
        };
        categories: {
            [catName: string]: {
                mutuallyExclusive: boolean;
                codes: string[];
            };
        };
    }

    const systemCodesDefault: SystemCodes = {
        order: {},
        categories: {},
    };

    systemCodesDefault.order[CodeOrder.CATEGORY_ORDER_ID] = {
        sequence: [],
        alphabetical: true,
    };

    export var PRODUCTION = {
        allowUseOtherBates: new Chain<boolean>({
            defaultVal: false,
        }),
        systemCodes: new Chain<SystemCodes>({
            defaultVal: systemCodesDefault,
        }),
        lastProtocol: new Chain<number>({
            defaultVal: null,
        }),
    };

    export var PREDICTION = {
        rigorous: new Chain<boolean>({
            defaultVal: false,
            isValid: function (v) {
                return v === !!v;
            },
        }),
    };

    export enum BatchServiceNotificationVisibility {
        NEVER_SHOW = "NEVER_SHOW",
        ALWAYS_SHOW = "ALWAYS_SHOW",
    }

    export const THREADING = {
        showToastNotifications: new Chain<BatchServiceNotificationVisibility>({
            defaultVal: BatchServiceNotificationVisibility.NEVER_SHOW,
        }),
    };

    export const NEAR_DUPE = {
        showToastNotifications: new Chain<BatchServiceNotificationVisibility>({
            defaultVal: BatchServiceNotificationVisibility.NEVER_SHOW,
        }),
    };

    export const ORGANIZATION = {
        // Value is a list of Org IDs that a user has acknowledged the first-time parent org message
        // for.
        acknowledgedParentOrgMessages: new Chain<number[]>({ defaultVal: [] }),
    };

    function prizmZoomObject(toWidth: boolean) {
        return new Chain<{ scale: number; toWidth: boolean; toFull: boolean }>({
            isVisible: false,
            defaultVal: { scale: 1, toWidth: toWidth, toFull: false },
            isValid: function (v) {
                return Is.number(v.scale) && Is.boolean(v.toWidth) && Is.boolean(v.toFull);
            },
        });
    }

    export type SectionPrefs = {
        sectionId: string;
        unpinnedIds: string[];
        pinnedOrder: string[];
    };
    export type CodingPrefs = {
        sectionIds: string[];
        showBinders: boolean;
        sections: SectionPrefs[];
    };
    export type CodingPresetsPrefs = {
        name: string;
        mutator: unknown[][];
    }[];
    export type MetadataPrefs = {
        showUnpinned: boolean;
        showEmpty: boolean;
    };
    export type CodeAsPreviousPrefs = {
        source: string;
        ratingMode: string;
        freeformCodesMode: string;
    };
    export type DefaultStampPrefs = {
        asPrevious: boolean;
        // stampId being undefined means no stamp.
        // undefined translates to the field not existing in the backend json.
        stampId: number | undefined;
    };
    export type ReviewModuleLayout = string[] | CodingPrefs | CodingPresetsPrefs | MetadataPrefs;

    function fullScreenLayoutObject(defaultVal: PanelLayout[] = []) {
        return new Chain<PanelLayout[]>({
            isVisible: true,
            defaultVal,
            isValid: function (val) {
                if (!Is.array(val)) {
                    return false;
                }
                for (const pref of val) {
                    if (pref) {
                        // null values are ok
                        if (
                            !Is.object(pref)
                            || !Is.defined(pref.name)
                            || !Is.string(pref.name)
                            || !Is.defined(pref.width)
                            || !Is.number(pref.width)
                            || !Is.defined(pref.groups)
                            || !Is.array(pref.groups)
                            || !Is.defined(pref.expanded)
                            || !Is.number(pref.expanded)
                            || (Is.defined(pref.savedId) && !Is.number(pref.savedId))
                        ) {
                            return false;
                        }
                        // Check if groups has type GroupLayout[]
                        for (const grp of pref.groups) {
                            if (
                                !Is.defined(grp.heightBasis)
                                || !Is.number(grp.heightBasis)
                                || !Is.defined(grp.modules)
                                || !Is.array(grp.modules)
                                || !grp.modules.every(Is.string)
                                || !Is.defined(grp.active)
                                || !Is.string(grp.active)
                            ) {
                                return false;
                            }
                        }
                    }
                }
                return true;
            },
        });
    }

    function fullScreenReviewStringArrayObject() {
        return new Chain<string[][]>({
            isVisible: false,
            defaultVal: [],
            isValid: function (val) {
                if (!Is.array(val)) {
                    return false;
                }
                for (const pref of val) {
                    if (pref) {
                        // null values are ok
                        if (!Is.array(pref) || (Is.array(pref) && !pref.every(Is.string))) {
                            return false;
                        }
                    }
                }
                return true;
            },
        });
    }

    function fullScreenReviewMetadataObject() {
        return new Chain<MetadataPrefs[]>({
            isVisible: true,
            defaultVal: [],
            isValid: function (val) {
                if (!Is.array(val)) {
                    return false;
                }
                for (const pref of val) {
                    if (pref) {
                        // null values are ok
                        if (
                            !Is.object(pref)
                            || !Is.defined(pref.showUnpinned)
                            || !Is.boolean(pref.showUnpinned)
                            || !Is.defined(pref.showEmpty)
                            || !Is.boolean(pref.showEmpty)
                        ) {
                            return false;
                        }
                    }
                }
                return true;
            },
        });
    }

    function fullScreenReviewCodingObject() {
        return new Chain<CodingPrefs[]>({
            isVisible: true,
            defaultVal: [],
            isValid: function (val) {
                if (!Is.array(val)) {
                    return false;
                }
                for (const pref of val) {
                    if (pref) {
                        // null values are ok
                        if (
                            !Is.object(pref)
                            || !Is.defined(pref.sectionIds)
                            || !Is.array(pref.sectionIds)
                            || !pref.sectionIds.every(Is.string)
                            || !Is.defined(pref.showBinders)
                            || !Is.boolean(pref.showBinders)
                            || !Is.defined(pref.sections)
                            || !Is.array(pref.sections)
                        ) {
                            return false;
                        }
                        // Check if sections has type SectionPrefs[]
                        for (const section of pref.sections) {
                            if (
                                !Is.object(section)
                                || !Is.defined(section.sectionId)
                                || !Is.string(section.sectionId)
                                || !Is.defined(section.pinnedOrder)
                                || !Is.array(section.pinnedOrder)
                                || !section.pinnedOrder.every(Is.string)
                                || !Is.defined(section.unpinnedIds)
                                || !Is.array(section.unpinnedIds)
                                || !section.unpinnedIds.every(Is.string)
                            ) {
                                return false;
                            }
                        }
                    }
                }
                return true;
            },
        });
    }

    function fullScreenReviewCodingPresetsObject() {
        return new Chain<CodingPresetsPrefs[]>({
            isVisible: false,
            defaultVal: [[]],
            isValid: (val) => {
                if (!Is.array(val)) {
                    return false;
                }
                for (let pref of val) {
                    /* Nulls, undefined, and empty arrays are okay, and all mean "this full screen
                layout doesn't have coding presets." We have to deal with all three because:
                1) nulls or undefined are what automatically fills in empty layout indices when
                deleting layouts or filling in indices for projects and users that already had
                layouts saved when coding presets were added to full screen layouts, and 2) we need
                the defaultVal to be [[]] to be compatible with existing code usages of
                REVIEW.fullScreenCodingPresetsLast, which causes [] to be passed in as {@link pref}
                here.
                */
                    if (pref !== null && Is.defined(pref)) {
                        if (!Is.array(pref)) {
                            return false;
                        }
                        const presets = <any[]>pref;
                        if (
                            (presets.length !== 0 && presets.length !== 9)
                            || !presets.every((p) => p)
                        ) {
                            return false;
                        }
                    }
                }
                return true;
            },
        });
    }

    function pdflikeZoomPref() {
        return new Chain<{ dim: ScrollViewer.ZoomDim; scale: number }>({
            isVisible: false,
            defaultVal: { dim: ScrollViewer.ZoomDim.WIDTH, scale: 1 },
            isValid: function (v) {
                return Is.object(v) && Is.defined(v.dim) && Is.defined(v.scale);
            },
        });
    }

    function codeAsPreviousPref(): TimeChain<CodeAsPreviousPrefs> {
        return new TimeChain<CodeAsPreviousPrefs>({
            isVisible: false,
            defaultVal: {
                source: CodeAsPrevious.defaultSource,
                ratingMode: CodeAsPrevious.defaultRatingMode,
                freeformCodesMode: CodeAsPrevious.defaultFreeformCodesMode,
            },
            isValid: (val: CodeAsPreviousPrefs) => {
                return (
                    CodeAsPrevious.isSource(val.source)
                    && CodeAsPrevious.isRatingMode(val.ratingMode)
                    && CodeAsPrevious.isFreeformCodesMode(val.freeformCodesMode)
                );
            },
        });
    }

    export interface UnpinnedPreference<T> {
        unpinned: T[];
        pinnedOrder: T[];
    }

    export var REVIEW = {
        openCategories: new Chain<number[]>({
            defaultVal: [],
            isVisible: false,
            isValid: function (v) {
                return Is.array(v) && v.every(Is.num);
            },
        }),
        zoom: pdflikeZoomPref(), // Zoom for the PDF viewer
        nativeZoom: pdflikeZoomPref(),
        fontSize: new Chain<number>({
            isVisible: false,
            defaultVal: 14,
            isValid: function (v) {
                return [6, 8, 10, 12, 14, 18, 24, 30, 36].indexOf(v) !== -1;
            },
        }),

        lastView: new Chain<View>({
            isVisible: false,
            defaultVal: View.IMAGE,
            isValid: Is.string,
        }),
        reviewWindowVisits: new Chain<number>({
            defaultVal: 0,
        }),

        lastViewChat: new Chain<View>({
            isVisible: false,
            defaultVal: View.CHAT,
            isValid: Is.string,
        }),

        lastViewMedia: new Chain<View>({
            isVisible: false,
            defaultVal: View.MEDIA,
            isValid: Is.string,
        }),

        lastViewSpreadsheet: new Chain<View>({
            isVisible: false,
            defaultVal: View.SPREADSHEET,
            isValid: Is.string,
        }),

        lastViewNativeImage: new Chain<View>({
            isVisible: false,
            defaultVal: View.IMAGE,
            isValid: Is.string,
        }),

        suggestions: new Chain<Category.Id[]>({
            isVisible: false,
            defaultVal: [],
            isValid: Is.array,
        }),
        pinnedMetadata: new Chain<number[]>({
            isVisible: true,
            defaultVal: [],
            isValid: Is.array,
        }),
        visibleMetadata: new Chain<{
            semanticFieldsVisible: boolean;
            originalFieldsVisible: boolean;
        }>({
            isVisible: true,
            defaultVal: { semanticFieldsVisible: true, originalFieldsVisible: false },
            isValid: function (v) {
                return Is.boolean(v.semanticFieldsVisible) && Is.boolean(v.originalFieldsVisible);
            },
        }),
        highlightUnique: new Chain<boolean>({
            isVisible: false,
            defaultVal: false,
            isValid: function (v) {
                return v === !!v;
            },
        }),
        showSelectionCheckboxes: new Chain<boolean>({
            isVisible: true,
            defaultVal: false,
            isValid: function (v) {
                return v === !!v;
            },
        }),
        collapseGroups: new Chain<boolean>({
            isVisible: true,
            defaultVal: false,
            isValid: function (v) {
                return v === !!v;
            },
        }),
        templates: new Chain<string[]>({
            isVisible: false,
            defaultVal: [],
            isValid: function (v) {
                if (!Is.array(v)) {
                    return false;
                }
                for (let text of v) {
                    if (text && !Is.string(text)) {
                        return false;
                    }
                }
                return true;
            },
        }),
        textLineWrap: new Chain<boolean>({
            isVisible: false,
            defaultVal: true,
            isValid: function (val) {
                return val === !!val;
            },
        }),
        pinnedTextQueries: new Chain<string[]>({
            isVisible: true,
            defaultVal: [],
            isValid: function (val) {
                return Is.array(val) && val.every(Is.string);
            },
        }),
        forceShowCaPChangedBanner: new Chain<boolean>({
            isVisible: true,
            defaultVal: false,
            isValid: (val) => {
                return val === !!val;
            },
        }),
        showNoteToast: new Chain<boolean>({
            isVisible: true,
            defaultVal: true,
            isValid: function (f) {
                return f === !!f;
            },
        }),
        customStamps: new Chain<boolean>({
            isVisible: true,
            defaultVal: false,
            isValid: (f) => {
                return f === !!f;
            },
        }),
        // Tracks recently used redaction stamps.
        recentStamps: new Chain<number[]>({
            isVisible: true,
            defaultVal: [],
            isValid: Is.array,
        }),
        defaultStamp: new Chain<DefaultStampPrefs>({
            isVisible: true,
            defaultVal: {
                asPrevious: false,
                stampId: undefined,
            },
            isValid: (val) => {
                return Is.object(val) && Is.boolean(val.asPrevious);
            },
        }),
        fullScreenMode: new Chain<boolean>({
            isVisible: true,
            defaultVal: true,
            isValid: function (f) {
                return f === null || f === !!f;
            },
        }),
        textHighlighterColor: new Chain<string>({
            isVisible: false,
            defaultVal: "YELLOW",
            isValid: Is.string,
        }),

        unitization: new Chain<boolean>({
            isVisible: true,
            // Show unitization if the user has not explicitly set a preference.
            defaultVal: true,
            isValid: function (val) {
                return val === !!val;
            },
        }),

        unpinnedCategories: new Chain<UnpinnedPreference<Category.Id>>({
            isVisible: true,
            defaultVal: { unpinned: [], pinnedOrder: [] },
            isValid: (v) => {
                if (!Is.object(v)) {
                    return false;
                }
                if (
                    !(
                        "unpinned" in v
                        && Is.array(v.unpinned)
                        && v.unpinned.every(Is.number)
                        && "pinnedOrder" in v
                        && Is.array(v.pinnedOrder)
                        && v.pinnedOrder.every(Is.number)
                    )
                ) {
                    return false;
                }
                const unpinnedSet = new Set(v.unpinned);
                const pinnedSet = new Set(v.pinnedOrder);
                if (
                    Obj.some(pinnedSet.entries(), (v) => unpinnedSet.has(v))
                    || Obj.some(unpinnedSet.entries(), (v) => pinnedSet.has(v))
                ) {
                    return false;
                }
                return true;
            },
        }),

        hiddenReviewLayouts: new Chain<number[]>({
            defaultVal: [],
            isValid: Is.array,
        }),

        classicLastHitHighlightTab: new Chain<HitHighlightType>({
            isVisible: true,
            defaultVal: "search" as HitHighlightType.SEARCH,
            isValid: Is.string,
        }),

        // Tracks the last assignment id a reviewer was using when reviewing. This state allows us to
        // know when a user leaves an assignment and triggers an assignment layout save when applicable.
        lastAssignmentId: new Chain<number>({
            isVisible: true,
            defaultVal: 0,
            isValid: (val) => {
                return Is.number(val);
            },
        }),

        // Tracks what the last Story object the user had selected on the review window so that switching
        // between documents will correctly remember to select that Story first in the Storybuilder module.
        lastChronId: new Chain<number>({
            isVisible: false,
            defaultVal: -1,
            isValid: (val) => {
                return Is.number(val);
            },
        }),

        fullScreenLayoutLast: fullScreenLayoutObject(),
        fullScreenToolbarLast: fullScreenReviewStringArrayObject(),
        fullScreenHitHighlightLast: fullScreenReviewStringArrayObject(),
        fullScreenMetadataLast: fullScreenReviewMetadataObject(),
        fullScreenHistoryLast: fullScreenReviewStringArrayObject(),
        fullScreenAnnotationsLast: fullScreenReviewStringArrayObject(),
        fullScreenCodingLast: fullScreenReviewCodingObject(),
        fullScreenCodingPresetsLast: fullScreenReviewCodingPresetsObject(),

        codeAsPreviousSettings: codeAsPreviousPref(),

        // Deprecated and no longer used. Left here because it still exists in the database.
        newViewerNotification: new Chain<boolean>({
            defaultVal: false,
        }),

        autoCodeTooltipShown: new Chain<boolean>({
            defaultVal: false,
        }),

        showChronHighlightBanner: new Chain<boolean>({
            defaultVal: true,
        }),

        diffViewerVariantsSort: new Chain<SortType>({
            defaultVal: TextVariantSort.DEFAULT.type,
            isValid: (val) => Object.values(SortType).includes(val),
        }),
    };

    export interface DashboardRecentlyAddedTime {
        chronId: number;
        relTime: string;
    }

    export interface DashboardCustomSearch {
        chronId: number;
        ratings: string[];
        categories: number[];
        codes: number[];
        relTime?: string;
    }

    function isStorybuilderDashboardRelativeTime(val) {
        return Arr.contains(["hour", "day", "week", "month"], val);
    }

    export var CHRONOLOGY = {
        visibleMetadata: new Chain<number[]>({
            isVisible: true,
            defaultVal: [],
            isValid: Is.array,
        }),
        showDescription: new Chain<boolean>({
            isVisible: true,
            defaultVal: true,
            isValid: function (val) {
                return val === !!val;
            },
        }),
        showRelevance: new Chain<boolean>({
            isVisible: true,
            defaultVal: true,
            isValid: function (val) {
                return val === !!val;
            },
        }),
        showMetadata: new Chain<boolean>({
            isVisible: true,
            defaultVal: true,
            isValid: function (val) {
                return val === !!val;
            },
        }),
        showEvents: new Chain<boolean>({
            isVisible: true,
            defaultVal: true,
            isValid: function (val) {
                return val === !!val;
            },
        }),
        showSource: new Chain<boolean>({
            isVisible: true,
            defaultVal: false,
            isValid: function (val) {
                return val === !!val;
            },
        }),
        sort: new Chain<{ property: string; descending: boolean }>({
            isVisible: true,
            defaultVal: { property: "chronDate", descending: false },
        }),
        searchDocContents: new Chain<boolean>({
            isVisible: true,
            defaultVal: false,
            isValid: function (val) {
                return val === !!val;
            },
        }),
        dashboardRecentlyAddedTime: new Chain<DashboardRecentlyAddedTime[]>({
            isVisible: true,
            defaultVal: [],
            isValid: function (val) {
                if (!Is.array(val)) {
                    return false;
                }
                for (const pref of val) {
                    if (
                        !Is.object(pref)
                        || !Is.number(pref.chronId)
                        || !isStorybuilderDashboardRelativeTime(pref.relTime)
                    ) {
                        return false;
                    }
                }
                return true;
            },
        }),
        dashboardCustomSearch: new Chain<DashboardCustomSearch[]>({
            isVisible: true,
            defaultVal: [],
            isValid: function (val) {
                if (!Is.array(val)) {
                    return false;
                }
                for (let pref of val) {
                    if (
                        !Is.number(pref.chronId)
                        || !Is.array(pref.ratings)
                        || !pref.ratings.every((r) => Arr.contains(["hot", "warm", "cold"], r))
                        || !Is.array(pref.categories)
                        || !pref.categories.every(Is.num)
                        || !Is.array(pref.codes)
                        || !pref.codes.every(Is.num)
                        || (pref.relTime && !isStorybuilderDashboardRelativeTime(pref.relTime))
                    ) {
                        return false;
                    }
                }
                return true;
            },
        }),

        // listId: chron object type + chron object id (e.g. "DEPOSITION12")
        // See ChronologyTaskList.TaskListPrefs for usage
        hiddenTaskLists: new Chain<{ [listId: string]: boolean }>({
            isVisible: true,
            defaultVal: {},
            isValid: function (val) {
                return Is.object(val) && Object.values(val).every(Is.boolean);
            },
        }),
        taskSortOrder: new Chain<{ [listId: string]: string }>({
            isVisible: true,
            defaultVal: {},
            isValid: function (val) {
                return Is.object(val) && Object.values(val).every(Is.string);
            },
        }),
        taskFilter: new Chain<{ [listId: string]: string }>({
            isVisible: true,
            defaultVal: {},
            isValid: function (val) {
                return Is.object(val) && Object.values(val).every(Is.string);
            },
        }),
        pinnedDefaultWritingAssistantTemplates: new Chain<WritingAssistantTemplateId[]>({
            isVisible: true,
            defaultVal: [],
            isValid: (val) => val instanceof Array,
        }),
    };

    export const OUTLINE_EDITOR = {
        showNavigator: new Chain<boolean>({
            isVisible: true,
            defaultVal: false,
            isValid: function (v) {
                return !!v === v;
            },
        }),
        // TODO: uncomment when adding draft context
        /*
    showOutlineContext: new Chain<boolean>({
        isVisible: true,
        defaultVal: true,
        isValid: function(val) { return val === !!val; }
    })
    */
    };

    export const DEPOSITION = {
        fullScreenLayoutLast: fullScreenLayoutObject(DepoFullScreenConstants.defaultLayout),
    };

    /*
     * NOTE: This method is intended for backwards-compatibility only.  Anyone using localStorage
     * going forward can use localStorage.getItem/setItem/removeItem directly.
     * Get a locally stored value. Defaults to fetching from local storage, but will check cookies
     * as well (deleting them afterwards).
     * Anything you store will be converted to a string internally, and any returned value will be a
     * string (except null, indicating nothing has been stored under that key).
     * This will never return undefined.
     */
    export function getLocalValue(name: string) {
        // Guaranteed to return null if there is no value stored.
        var stored = LocalStorage.getItem(name);
        if (stored === null) {
            stored = dojo_cookie(name);
            if (Is.defined(stored)) {
                LocalStorage.setItem(name, stored);
                // Clear any cookie at this name.
                // It's possible that a cookie could 'escape' if someone sets the value in localStorage
                // before ever calling this method.  However, this should be fine for how we're using
                // this method now, and we shouldn't be using it more in the future.
                dojo_cookie(name, null, { expires: -1 });
            } else {
                // dojo_cookie returns undefined if there is no cookie with the given name, but the
                // local storage spec indicates to return null if there is no value stored, so follow
                // that behavior.
                stored = null;
            }
        }
        return stored;
    }

    if (JSP_PARAMS.Preference) {
        Object.entries(JSP_PARAMS.Preference).forEach(([setName, prefs]) => {
            Object.entries<Chain<never>>((<never>Preference)[setName] || {}).forEach(
                ([key, chain]) => {
                    chain.load(
                        setName,
                        key,
                        (prefs[key] || []).map((p) => new Preference(p)),
                    );
                },
            );
        });
    }
}

export = Preference;
